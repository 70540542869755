// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const TARGET_ENVIRONMENT = process.env.NEXT_PUBLIC_TARGET_ENVIRONMENT;

if (TARGET_ENVIRONMENT === 'staging' || TARGET_ENVIRONMENT === 'production') {
  init({
    dsn:
      SENTRY_DSN ||
      'https://9e3d2ee494be42919e11ee08d44fe3bd@o564956.ingest.sentry.io/5955750',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: TARGET_ENVIRONMENT,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
