export const API_ROUTES = {
  COMPANIES: '/api/v1/companies/basic_index',
  COMPANY_LIST: '/api/v1/companies',
  RECRUITER_SIGNUP: '/api/v1/recruiter',
  SIGNIN: '/api/v1/login',
  MANAGER_SIGNUP: '/api/v1/student_societies',
  USER_INFO: '/api/v1/user',
  USER_CONFIRMATION: '/api/v1/users',
  STUDENT_SOCIETIES: '/api/v1/student_societies',
  SIMILAR_STUDENT_SOCIETIES: '/similar',
  UNIVERSITIES: '/api/v1/universities',
  TOPICS: '/api/v1/tags?type=Topic',
  CAMPAIGNS: '/api/v1/touchpoints',
  TOUCHPOINTS: '/api/v1/touchpoints',
  BASIC_TOUCHPOINTS: '/api/v1/touchpoints/basic_index',
  BASIC_TOUCHPOINTS_V2: '/api/v2/touchpoints',
  CAMPAIGN_QUESTIONS: '/api/v1/questions/create_multiple',
  CAMPAIGN_QUESTIONS_TYPES: '/api/v1/questions/question_types',
  COMPANY_PROFILE: '/api/v1/company',
  SIMILAR_COMPANIES: '/similar',
  BENEFITS: '/api/v1/benefits',
  COMPANY_BENEFIITS: '/api/v1/company/companies_benefits',
  COMPANY_CANDIDATE_TRANSACTION_TYPES:
    '/api/v1/company/candidate_transactions_type',
  COUNTRIES: '/api/v1/countries',
  COMPANY_NATIONALITIES: '/api/v1/company/nationalities',
  COMPANY_INSIGHTS: '/api/v1/company/insights',
  COMPANY_SIZES: '/api/v1/company_sizes',
  PASSWORD_RESET: '/api/v1/password',
  APPLICATIONS: '/touchpoint_applications',
  REJECT_WAITLIST: '/reject_waitlist',
  REVIEW: '/review',
  DASHBOARD: '/dashboard',
  CLONE: '/clone',
  UPDATE_STATUS: '/api/v1/update_status',
  ROOMS: '/api/v1/rooms',
  ROOM_MESSAGES: '/messages',
  INTERACTED_STUDENT_SOCIETIES:
    '/api/v1/candidate/student_societies/interacted',
  EMAIL_VERIFICATION: '/api/v1/user/email',
  CANDIDATE_SIGNUP: '/api/v1/student_society_registrations',
  STUDENT_SOCIETY_REGISTRATION: '/api/v1/student_society_registrations',
  HIGHLIGHTS: '/api/v1/highlights',
  SOCIETY_HIGHLIGHTS: '/api/v1/student_societies_highlights',
  STUDENT_SOCIETY: '/api/v1/student_society',
  STUDENT_SOCIETY_CANDIDATES: '/api/v1/student_society/candidates',
  STUDENT_SOCIETY_ROOM: '/api/v1/student_society/rooms',
  COMPANY_ROOM: '/api/v1/company/rooms',
  CANDIDATE_ROOM: '/api/v1/candidate/rooms',
  STUDENT_SOCIETY_AYRSHARE: '/api/v1/student_society/ayrshare_profile',
  OFFERINGS: '/api/v1/tags?type=Offering',
  FLAGSHIP_EVENTS: '/api/v1/student_society/flagship_events',
  SOCIETY_CUSTOM_LINKS: '/api/v1/student_society/custom_links',
  SOCIETY_STATS: '/api/v1/student_society/stats',
  COMPANY_STATS: '/api/v1/company/stats',
  CANDIDATE_CREATE: '/api/v1/candidate/login',
  CANDIDATE_TOUCHPOINT: '/api/v1/candidate/touchpoints',
  CANDIDATE: '/api/v1/candidate',
  FOLLOW: '/api/v1/followings',
  SUBJECTS: '/api/v1/subjects',
  UPLOAD_NEWSLETTER_IMAGE:
    '/api/v1/student_society/news_letters/[id]/upload_image',
  DELETE_NEWSLETTER_IMAGE:
    '/api/v1/student_society/news_letters/[id]/remove_image',
  DUPLICATE_NEWSLETTER: '/api/v1/student_society/news_letters/[id]/duplicate',
  SOCIETY_NEWSLETTERS: '/api/v1/student_society/news_letters',
  GOALS: '/api/v1/goals',
  CANDIDATE_GOALS: '/api/v1/candidates_goals',
  CANDIDATE_INTERESTS: '/api/v1/interests',
  CANDIDATE_INTERESTS_UPDATE: '/api/v1/interests/create_multiple',
  DEGREES: '/api/v1/degrees',
  MATCHES: '/api/v3/matches?group_by=touchpointable',
  UPDATE_MATCHES: '/api/v2/matches',
  PROGRAMS: '/api/v1/programs',
  CANDIDATE_EMAIL_UPDATE: '/api/v1/user',
  INVALID_URL_REPORT: '/api/v1/invalid_url_reports',
  ACCEPT_INVITATION: '/api/v1/invitations/accept',
  RECENT_SEARCHES: '/api/v1/recent_searches',
  DISLIKE_MATCH: '/api/v2/matches',
  CITIES: '/api/v1/cities',
  GENDERS: '/api/v1/genders',
  CANDIDATE_PREFERENCE_OPTIONS: '/api/v1/candidate_preference_options',
  RACE_ETHNICITY: '/api/v1/tags?type=Ethnicity',
  LANGUAGE: '/api/v1/tags?type=Language',
  INDUSTRY: '/api/v1/tags?type=Industry',
  AREA_OF_RESPONSIBILITY: '/api/v1/tags?type=AreaOfResponsibility',
  EDUCATION_REQUIREMENT: '/api/v1/tags?type=EducationRequirement',
  CANDIDATE_NOTIFICATIONS: '/api/v1/notifications',
  SOCIETY_TOUCHPOINTS: '/api/v1/student_society/touchpoints',
  SOCIETY_COHOST_APPLICATIONS: '/api/v1/student_society/cohost_applications',
  APPLICATION_RATING: '/api/v1/touchpoint_applications/[id]/update_rating',
  AYRSHARE_POSTS: '/api/v1/student_society/ayrshare_posts',
  RELEASE_TICKETS: '/release_tickets',
  OPPORTUNITY_TYPES: '/api/v1/opportunity_types?with_part_time=true',
  EVENT_CATEGORIES: '/api/v1/event_categories',
  PREVIOUS_MATCHES: '/api/v3/matches/previous_matches?group_by=touchpointable',
  COMPANY_EMPLOYEES_COUNTS: '/api/v1/companies/employees_counts',
  COMPANY_TOUCHPOINTS: '/api/v1/company/touchpoints',
  COMPANY_COHOST_APPLICATIONS: '/api/v1/company/cohost_applications',
  COMPANY_TOUCHPOINT_APPLICATIONS: '/api/v1/company/touchpoint_applications',
  CURRENCIES: '/api/v1/currencies',
  MATCH_PREFERENCES: '/api/v3/matches/mark_preferred',
  CANDIDATE_APPLICATIONS: '/api/v1/candidate/applications',
  CANDIDATE_COVER_LETTER: '/api/v1/candidate/touchpoints/[id]/cover_letter',
  CANDIDATE_QUESTION_REPLY: '/api/v1/candidate/touchpoints/[id]/question_reply',
  CANDIDATE_DOWNLOAD_CV: '/api/v1/candidate/cv',
  RECRUITER_CREATE_WITH_COMPANY: '/api/v1/recruiter/create_with_company',
  RECRUITER_CONFIRM_EMAIL: '/api/v1/recruiter/confirm_email',
  RECRUITER_RESEND_CONFIRMATION: '/api/v1/recruiter/resend_confirmation',
  COMPANY_DASHBOARD: '/api/v1/company/dashboard',
  AI_SUGGESTIONS_WORK_EXPERIENCE_FROM_SCRATCH:
    '/api/v1/ai_suggestions/work_history_from_scratch',
  AI_SUGGESTIONS_WORK_EXPERIENCE_IMPROVEMENTS:
    '/api/v1/ai_suggestions/work_history_improvements',
  AI_SUGGESTIONS_EXTRA_CURRICULAR_ACTIVITIES_FROM_SCRATCH:
    '/api/v1/ai_suggestions/extra_curricular_activities_suggestion',
  AI_SUGGESTIONS_EXTRA_CURRICULAR_ACTIVITIES_IMPROVEMENTS:
    '/api/v1/ai_suggestions/extra_curricular_activities_improvements',
  MANAGER_CREATE_WITH_SOCIETY: '/api/v1/manager/create_with_society',
  MANAGER_CONFIRM_EMAIL: '/api/v1/manager/confirm_email',
  MANAGER_RESEND_CONFIRMATION: '/api/v1/manager/resend_confirmation',
  MANAGER_USER: '/api/v1/manager',
  FETCH_LINK_TREE: '/api/v1/manager/fetch_linktree_links',
  SOCIETY_DASHBOARD: '/api/v1/student_society/dashboard',
  STUDENT_SOCIETY_POSTS: '/api/v1/student_societies/:student_society_id/posts',
  SOCIETY_MANAGERS: '/api/v1/student_society/managers',
  COMMITTEE: 'api/v1/student_society/managers/?for_listing=true',
  TALENT_POOL_MESSAGE: '/api/v1/company/messages/message_talent_pool',
  REPLACEMENT_MESSAGE_OPTIONS:
    '/api/v1/company/messages/inline_message_replacement_options',

  TALENT_POOL: '/api/v1/company/touchpoints/talent_pool',
  SOCIETY_PROFILE_VIEWERS: '/api/v1/student_society/profile_viewers',
  JOB_TALENT_POOL: '/api/v1/company/touchpoints/[id]/talent_pool',
  TRANSACTION_CANDIDATE:
    '/api/v1/company/touchpoints/[id]/candidate_with_transactions',
  CANDIDATE_DASHBOARD: '/api/v1/candidate/dashboard',
  TODO_TOUCHPOINTS: '/to_do',
  EVENT_RECOMMENDATIONS: '/event_recommendations',
  OPPORTUNITY_RECOMMENDATION: '/from_following_companies',
  PAST_MATCHES: '/api/v3/matches/previous_touchpoint_matches',
  RECOMMENDED: '/recommended',
  ADD_SOCIETY_SUBSCRIBERS:
    '/api/v1/student_society/student_society_registrations',
  INVITE_CANDIDATES_TO_COMMUNITY:
    '/api/v1/student_society/student_society_registrations/invite_candidates',
  UPLOAD_SOCIETY_SUBSCRIBERS:
    '/api/v1/student_society/student_society_registrations/bulk_upload_csv',
  SOCIETY_SPONSORSHIPS_PLANS: '/api/v1/student_society/sponsorship_plans',
  SOCIETY_MESSAGE_SPONSOR: '/api/v1/student_society/messages/message_sponsor',
  SOCIETY_INVITE_SPONSORS: '/api/v1/student_society/sponsorship_invitations',
  SOCIETY_AI_MESSAGE_SUGGESTION:
    '/api/v1/ai_suggestions/sponsorship_message_suggestion',
  SOCIETY_SPONSORS: '/api/v1/student_society/sponsorships',
  INVITE_ATTENDEES:
    '/api/v1/student_society/student_society_registrations/invite_attendees',
  INVITE_EVENT_VIEWERS:
    '/api/v1/student_society/student_society_registrations/invite_event_viewers',
  INVITE_PROFILE_VIEWERS:
    '/api/v1/student_society/student_society_registrations/invite_profile_viewers',
  STUDENT_SOCIETY_REGISTRATION_STATS:
    '/api/v1/student_society/student_society_registrations/dashboard',
  STUDENT_SOCIETY_MESSAGES: '/api/v1/student_society/messages/bulk_message',
  COMPANY_SPONSORSHIP_INVITE_LIST: '/api/v1/company/sponsorship_invitations',
  RECRUITER_UPDATE: '/api/v1/recruiter',
  COMPANY_SPONSORSHIPS: '/api/v1/company/sponsorships',
  SOCIETY_TALENT_POOL: '/api/v1/student_societies/talent_pool',
  SOCIETY_COMMUNITY_POOL:
    '/api/v1/student_society/student_society_registrations',
  INVITE_TOUCHPOINT_GUEST: '/invite',
  EVENT_GUIDE_VIEWED: '/event_guide_viewed',
  SEND_FILL_DATA_EMAIL: '/api/v1/student_society/send_fill_data_email',
  SOCIETY_EVENT_VIEWERS: '/api/v1/student_society/event_viewers',
  CREATABLE_FOR: '/api/v1/creatable_fors',
  CANDIDATE_SEARCH_LISTS: '/api/v1/candidate/search_lists',
};
