import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from 'lib/providers/AuthProvider';
import { parseResponse } from 'lib/utils/parser';
import {
  ApplicationResponseModalEnum,
  CandidateApplicationType,
} from 'lib/models/candidate-applications';
import { useCommonList } from 'lib/contexts/common-list-context';
import { RoomAttributes } from 'lib/models/candidate-messages';
import { PAGE_ROUTES } from 'lib/page-routes';
import { CHROME_EXTENSION_STORE_LINK } from 'utils/urls';
import { useResponsive } from './useResponsive';

export type CallbackFn = () => Promise<void>;

export type CohostIds = { creatableForId: string; touchpointId: string };

export enum FLOW {
  ONBOARDING = 'onboarding',
  MATCHING_PREFERENCES = 'matching_preferences',
  MATCHING_PREFERENCES_SAVE = 'matching_preferences_save',
  SEEN_ALL_MACTHES = 'seen_all_matches',
  PREVIOUS_MATCH_GENERATED = 'previous_match_generated',
  PREVIOUS_MATCH_SET_COMPLETED = 'previous_match_set_completed',
  MP_EMPTY_MATCH = 'mp_empty_match',
}

export const useApp = () => {
  const callbackRef = useRef<CallbackFn | null>(null);
  const router = useRouter();
  const { isCandidate, isAuthLoading, candidate } = useAuth();
  const { cities } = useCommonList();
  const { extension_installed } = candidate || {};
  const screens = useResponsive();

  const [routesVisited, setRoutesVisted] = useState<Array<string>>([]);
  const [email, setEmail] = useState<string | null>(null);
  const [societySearch, setSocietySearch] = useState<string | null>(null);
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const [shouldHideMobileFooter, setHideMobileFooter] = useState(false);
  const [followable_id, setFollowableId] = useState<string | null>(null);
  const [roomData, setRoomData] = useState<RoomAttributes | null>(null);

  /** sponsorship request from recruiter to be saved
   * when recruiter is not logged in
   * and tried to message society
   */

  const [recruiterMessage, setRecruiterMessage] = useState<{
    sponsorship_student_society_id: string;
    sponsorship_message: string;
    sponsorship_plan_id: string | null;
  } | null>(null);

  const [writeApplicationModalType, setWriteApplicationModalType] = useState<{
    type: ApplicationResponseModalEnum;
    application: CandidateApplicationType | null;
  } | null>(null);

  // On clicking back from the browser, removing the route from the routesVisted
  // and pushing -1 instead.

  function handleRouteChange(route: string) {
    setRoutesVisted((routes) => {
      if (routes[routes.length - 1] === '-1')
        return routes.slice(0, routes.length - 1);
      else return [...routes, route];
    });
  }

  function handleRemoveRoute() {
    setRoutesVisted((routes) => {
      const newRoutes = routes.slice(0, routes.length - 1);
      return [...newRoutes, '-1'];
    });
    return true;
  }

  function getPreviousRoute() {
    const previousRoute = router.asPath;
    setPreviousRoute(previousRoute);
  }

  const handleAutofillApplicationsClick = useCallback(() => {
    if (extension_installed) {
      router.push({
        pathname: PAGE_ROUTES.SEARCH_RESULTS,
        query: { quick_apply: true },
      });
    } else {
      window.open(CHROME_EXTENSION_STORE_LINK, '_blank');
    }
  }, [extension_installed]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.beforePopState(handleRemoveRoute);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', getPreviousRoute);
    return () => {
      router.events.off('routeChangeStart', getPreviousRoute);
    };
  }, [router]);

  function setCallback(callbackFn: CallbackFn | null) {
    callbackRef.current = callbackFn;
  }

  function getCallback() {
    return callbackRef.current;
  }

  const locationList = useMemo(() => {
    return cities?.map(({ id, name, country, hidden }) => {
      const countryAttr = parseResponse(country);

      return {
        id: id,
        value: `${name}`, //Changing this from id to name, need to test if this breaks anywhere.
        label: `${name}`,
        country: countryAttr,
        timezoneMapping: countryAttr?.formatted_timezone_mapping,
        hidden,
      };
    });
  }, [cities]);

  const isPublicPage =
    router.pathname === PAGE_ROUTES.COMPANIES ||
    router.pathname === PAGE_ROUTES.SOCIETIES ||
    router.pathname === PAGE_ROUTES.SOCIETY_PUBLIC ||
    router.pathname === PAGE_ROUTES.SHORTLINK_SOCIETY_PUBLIC ||
    router.pathname === PAGE_ROUTES.COMPANY_PUBLIC ||
    router.pathname === PAGE_ROUTES.JOB_PUBLIC ||
    router.pathname === PAGE_ROUTES.EVENT_PUBLIC ||
    router.pathname === PAGE_ROUTES.INTERNSHIP_PUBLIC ||
    router.pathname === PAGE_ROUTES.PUBLIC_SEARCH_PAGES ||
    router.pathname === PAGE_ROUTES.SOCIETY_SPONSOR_PUBLIC_PAGE;

  const isCandidateRoute =
    isPublicPage ||
    router.pathname === PAGE_ROUTES.CANDIDATE_DASHBOARD ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_SUCCESS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_GENERATING ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_SEEN_ALL ||
    router.pathname === PAGE_ROUTES.MANAGER ||
    router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING ||
    router.pathname === PAGE_ROUTES.AI_ONBOARDING ||
    router.pathname === PAGE_ROUTES.SEARCH_RESULTS ||
    router.pathname === PAGE_ROUTES.MATCHING_EXPERIENCE_PAGE ||
    router.pathname === PAGE_ROUTES.CANDIDATE_SETTINGS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
    router.pathname === PAGE_ROUTES.CANDIDATE_NOTIFICATIONS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES ||
    router.pathname === PAGE_ROUTES.CANDIDATE_BUILD_COVER_LETTER ||
    //add society page and universities society page in candidate route for new logo
    router.pathname === PAGE_ROUTES.SOCIETIES ||
    router.pathname === PAGE_ROUTES.UNIVERSITIES_SOCIETIES;

  const showFooter =
    router.pathname === PAGE_ROUTES.MANAGER ||
    router.pathname === PAGE_ROUTES.CANDIDATE_DASHBOARD ||
    router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
    router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
    router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
    router.pathname === PAGE_ROUTES.SEARCH_RESULTS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCH ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_SUCCESS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_GENERATING ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES_SEEN_ALL;

  const hideHeader = !!(
    (isPublicPage && (isCandidate || isAuthLoading)) ||
    router.pathname === PAGE_ROUTES.CANDIDATE_NOTIFICATIONS ||
    router.pathname === PAGE_ROUTES.CANDIDATE_SETTINGS ||
    shouldHideMobileFooter ||
    router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES ||
    router.pathname === PAGE_ROUTES.CANDIDATE_BUILD_COVER_LETTER ||
    router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING ||
    (isCandidate &&
      (router.pathname === PAGE_ROUTES.SOCIETY_PUBLIC ||
        router.pathname === PAGE_ROUTES.SHORTLINK_SOCIETY_PUBLIC ||
        router.pathname === PAGE_ROUTES.COMPANY_PUBLIC))
  );

  const headerVisibleSmall = hideHeader === false && showFooter === false;
  const footerVisibleSmall = hideHeader === false && showFooter === true;

  const isHeaderVisible = !screens.sm || headerVisibleSmall;
  const isFooterVisible = !!(screens.sm && footerVisibleSmall);

  return {
    roomData,
    setRoomData,
    setCallback,
    getCallback,
    routesVisited,
    email,
    setEmail,
    societySearch,
    setSocietySearch,
    shouldHideMobileFooter,
    setHideMobileFooter,
    locationList,
    followable_id,
    setFollowableId,
    handleRemoveRoute,
    writeApplicationModalType,
    setWriteApplicationModalType,
    previousRoute,
    setPreviousRoute,
    recruiterMessage,
    setRecruiterMessage,
    isPublicPage,
    isCandidateRoute,
    headerVisibleSmall,
    footerVisibleSmall,
    isHeaderVisible,
    isFooterVisible,
    handleAutofillApplicationsClick,
  };
};

export type UseAppReturnType = ReturnType<typeof useApp>;
