import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseCampaignsReturnType,
  useCampaigns as useCampaignsInternal,
} from 'hooks/useCampaigns.hook';

const initialContext: UseCampaignsReturnType = {
  onDelete: () => new Promise(noop),
  mutateCompanyCampaigns: () => new Promise(noop),
  touchpointViewCounterHandler: () => new Promise(noop),
  eventsList: [],
  size: 0,
  setSize: noop,
  hasMoreRecords: false,
  isLoadingMore: false,
  totalCount: undefined,
  isAPILoading: false,
  mutateEvents: () => new Promise(noop),
  selectedEventState: 0,
  setSelectedEventState: noop,
  liveOpportunityLists: [],
  onEasyApplyConfirm: () => new Promise(noop),
  onFeaturedToggle: () => new Promise(noop),
  getFeaturedIds: () => new Promise(noop),
  isInvitePopup: false,
  setInvitePopup: noop,
};

const CampaignsContext = createContext<UseCampaignsReturnType>(initialContext);

export const useCampaigns = (): UseCampaignsReturnType =>
  useContext(CampaignsContext);

export const CampaignsProvider: React.FC<{ isInvitePopup?: boolean }> = ({
  children,
}) => {
  const value = useCampaignsInternal();

  return (
    <CampaignsContext.Provider value={value}>
      {children}
    </CampaignsContext.Provider>
  );
};
