import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import { useMemo } from 'react';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { API_ROUTES } from 'lib/api-routes';
import { CitiesResponse, CityAttributes } from 'lib/models/city';
import { CompanySizeAttributes, CompanySizeResponse } from 'lib/models/company';
import { CountriesResponse, CountryAttributes } from 'lib/models/country';
import { DegreeAttributes } from 'lib/models/degree';
import { EVENT_KINDS, EVENT_MODES } from 'lib/models/event';
import {
  EventCategoriesAttributes,
  EventCategoriesList,
} from 'lib/models/event-categories';
import {
  OPPORTUNITY_TYPE,
  OpportunityTypesResponse,
  OPPORTUNITY_EXPERIENCE_LEVEL,
} from 'lib/models/opportunity';
import { Option } from 'lib/models/option';
import { ProgramsResponse } from 'lib/models/programs';
import { SubjectAttributes, SubjectsResponse } from 'lib/models/subject';
import { TagAttributes, TagType, TagsResponse } from 'lib/models/tag';
import {
  UniversitiesResponse,
  UniversityAttributes,
} from 'lib/models/university';
import { get } from 'lib/utils/http';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import {
  StudentSocietiesResponse,
  StudentSocietyAttributes,
} from 'lib/models/student-society';
import { useAuth } from 'lib/providers/AuthProvider';

export type EventCategoryDropdownOption = Option & {
  isDefault: boolean;
  industry_ids: Array<string>;
};

export type TCityCountyList = Array<
  Option & { country_name: string; country_id: string }
>;

export const useCommonList = () => {
  const { isManager, isRecruiter } = useAuth();
  const isOrganizationUser = isManager || isRecruiter;
  const { data: areaOfResponsibilitiesResponse } = useSWR<
    TagsResponse<TagType.AreaOfResponsibility>
  >(API_ROUTES.AREA_OF_RESPONSIBILITY, get, {
    revalidateOnFocus: false,
  });

  const areaOfResponsibilities = useMemo(() => {
    const list = parseArrayResponse<
      TagAttributes<TagType.AreaOfResponsibility>
    >(areaOfResponsibilitiesResponse);
    return list.map(({ id, name }) => ({
      id: id,
      value: id,
      label: name,
    }));
  }, [areaOfResponsibilitiesResponse]);

  const { data: opportunityTypes } = useSWR<OpportunityTypesResponse>(
    `${API_ROUTES.OPPORTUNITY_TYPES}`,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const internshipKinds = useMemo(() => {
    return opportunityTypes?.find(
      (item) => item.id === OPPORTUNITY_TYPE.INTERNSHIP
    )?.touchpointable_kinds;
  }, [opportunityTypes]);

  const internshipKindOptions = useMemo(() => {
    return internshipKinds?.map((item) => ({
      id: uuidv4(),
      value: item,
      label: item,
    }));
  }, [internshipKinds]);

  const eventKinds = useMemo(() => {
    const list = opportunityTypes?.find(
      (item) => item.id === OPPORTUNITY_TYPE.EVENT
    )?.event_categories;
    return list?.map(({ id, name }) => ({ id, value: id, label: name }));
  }, [opportunityTypes]);

  const roleLevels = useMemo(() => {
    const list = opportunityTypes?.find(
      (item) => item.id === OPPORTUNITY_TYPE.JOB
    )?.touchpointable_kind_mapping;

    if (!list) return;

    return Object.entries(list).map(([key, value]) => ({
      id: value,
      value: key,
      label: key,
    }));
  }, [opportunityTypes]);

  const experienceLevels = useMemo(() => {
    if (!roleLevels) return;

    return roleLevels.map(({ id, label }) => ({
      id: id.toString(),
      value: id.toString(),
      label:
        label === OPPORTUNITY_EXPERIENCE_LEVEL.EXPERT_LEVEL
          ? OPPORTUNITY_EXPERIENCE_LEVEL.EXPERT_LEVEL_SHORTENED
          : label,
    }));
  }, [roleLevels]);

  const { data: educationRequirementsResponse } = useSWR<
    TagsResponse<TagType.EducationRequirement>
  >(API_ROUTES.EDUCATION_REQUIREMENT, get, {
    revalidateOnFocus: false,
  });

  const educationRequirementsOptions = useMemo(() => {
    const list = parseArrayResponse<
      TagAttributes<TagType.EducationRequirement>
    >(educationRequirementsResponse);
    return list.map(({ id, name }) => ({
      id: id,
      value: name,
      label: name,
    }));
  }, [educationRequirementsResponse]);

  const { data: currencyResponse } = useSWR<Record<string, Array<string>>>(
    API_ROUTES.CURRENCIES,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const currencyOptions = useMemo(
    () =>
      currencyResponse?.currencies.map((key) => ({
        id: key,
        value: key,
        label: key,
      })),
    [currencyResponse]
  );

  const { data: eventCategoryResponse } = useSWR<EventCategoriesList>(
    API_ROUTES.EVENT_CATEGORIES,
    get,
    { revalidateOnFocus: false }
  );

  const eventCategoriesList = useMemo(() => {
    const categoryList = parseArrayResponse<EventCategoriesAttributes>(
      eventCategoryResponse
    );
    return categoryList.map(
      ({ id, name, default: isDefault, industry_ids }) => ({
        id: id,
        value: name,
        label: name,
        isDefault,
        industry_ids,
      })
    );
  }, [eventCategoryResponse]);

  const eventCategoriesOptions = useMemo(() => {
    const categoryList = parseArrayResponse<EventCategoriesAttributes>(
      eventCategoryResponse
    );
    return categoryList.map(({ id, name }) => ({
      id: id,
      value: id,
      label: name,
    }));
  }, [eventCategoryResponse]);

  const { data: companySizeResponse } = useSWR<CompanySizeResponse>(
    API_ROUTES.COMPANY_SIZES,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const companySizeOptions = useMemo(() => {
    const list = parseArrayResponse<CompanySizeAttributes>(companySizeResponse);
    return list.map(({ id, name }) => ({
      id: id,
      value: name,
      label: name,
    }));
  }, [companySizeResponse]);

  const { data: employeesCountResponse } = useSWR<string[]>(
    API_ROUTES.COMPANY_EMPLOYEES_COUNTS,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const employeesCountRange = useMemo(() => {
    return employeesCountResponse?.map((value) => ({
      id: value,
      value: value,
      label: `${value} employees`,
    }));
  }, [employeesCountResponse]);

  const { data: industriesResponse } = useSWR<TagsResponse<TagType.Industry>>(
    API_ROUTES.INDUSTRY,
    get,
    { revalidateOnFocus: false }
  );

  const industryOptions = useMemo(() => {
    const list =
      parseArrayResponse<TagAttributes<TagType.Industry>>(industriesResponse);
    return list.map(({ id, name }) => ({
      id: id,
      value: name,
      label: name,
    }));
  }, [industriesResponse]);

  const industryTypeOptions = useMemo(() => {
    const list =
      parseArrayResponse<TagAttributes<TagType.Industry>>(industriesResponse);
    return list.map(({ id, name }) => ({
      id: id,
      value: id,
      label: name,
    }));
  }, [industriesResponse]);

  const { data: topicsResponse } = useSWR<TagsResponse<TagType.Topic>>(
    [API_ROUTES.TOPICS],
    get,
    { revalidateOnFocus: false }
  );

  const topicOptions = useMemo(() => {
    const topics =
      parseArrayResponse<TagAttributes<TagType.Topic>>(topicsResponse);
    return topics.map(({ id, name }) => ({
      id,
      value: name,
      label: name,
    }));
  }, [topicsResponse]);

  const topicTypeOptions = useMemo(() => {
    const topics =
      parseArrayResponse<TagAttributes<TagType.Topic>>(topicsResponse);
    return topics.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [topicsResponse]);

  const { data: offeringResponse } = useSWR<TagsResponse<TagType.Offering>>(
    [API_ROUTES.OFFERINGS],
    get,
    { revalidateOnFocus: false }
  );

  const offeringOptions = useMemo(() => {
    const topics =
      parseArrayResponse<TagAttributes<TagType.Offering>>(offeringResponse);
    return topics.map(({ id, name }) => ({
      id,
      value: name,
      label: name,
    }));
  }, [offeringResponse]);

  const eventTypeOptions = useMemo(
    () =>
      EVENT_KINDS.map((kind) => {
        return {
          id: String(kind.id),
          label: kind.label,
          value: kind.value,
        };
      }),
    []
  );

  const eventModeOptions = useMemo(
    () =>
      EVENT_MODES.map((mode) => {
        return {
          id: String(mode.id),
          label: mode.label,
          value: String(mode.value),
        };
      }),
    []
  );

  const { data: universitiesResponse } = useSWR<UniversitiesResponse>(
    API_ROUTES.UNIVERSITIES,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const universities = useMemo(() => {
    const list = parseArrayResponse<UniversityAttributes>(universitiesResponse);
    return list.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [universitiesResponse]);

  const { data: degreesResponse } = useSWR<ProgramsResponse>(
    [API_ROUTES.DEGREES],
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const degrees = useMemo(() => {
    const list = parseArrayResponse<DegreeAttributes>(degreesResponse);
    return list.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [degreesResponse]);

  const { data: subjectsResponse } = useSWR<SubjectsResponse>(
    [API_ROUTES.SUBJECTS],
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const subjects = useMemo(() => {
    const list = parseArrayResponse<SubjectAttributes>(subjectsResponse);
    return list.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [subjectsResponse]);

  const studyYearOptions = [...Array(5).keys()].map((item) => ({
    id: String(item + 1),
    value: String(item + 1),
    label: String(item + 1),
  }));

  const { data: countriesResponse } = useSWR<CountriesResponse>(
    `${API_ROUTES.COUNTRIES}?timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const countries = useMemo(() => {
    if (countriesResponse) {
      const list = parseArrayResponse<CountryAttributes>(countriesResponse);
      return list.map(({ id, name }) => ({
        id,
        value: id,
        label: name,
      }));
    } else return undefined;
  }, [countriesResponse]);

  const { data: citiesResponse } = useSWR<CitiesResponse>(
    `${API_ROUTES.CITIES}?timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const cities = useMemo(() => {
    const list = parseArrayResponse<CityAttributes>(citiesResponse);
    return list;
  }, [citiesResponse]);

  const cityCountryList = useMemo(() => {
    return cities.map(({ id, name, country: countryResponse, hidden }) => {
      const country = parseResponse<CountryAttributes>(countryResponse);
      const country_name = country.name;
      const country_id = country.id;
      return {
        id,
        label: name,
        value: name,
        country_name,
        country_id,
        hidden,
      };
    });
  }, [cities]);

  const { data: candidatePerferencesResponse } = useSWR<{
    [key: string]: string;
  }>(API_ROUTES.CANDIDATE_PREFERENCE_OPTIONS, get, {
    revalidateOnFocus: false,
  });

  const genders = useMemo(() => {
    if (!candidatePerferencesResponse) return undefined;

    return Object.entries(candidatePerferencesResponse.genders).map(
      ([key, value]) => ({
        id: key,
        value: value,
        label: value,
      })
    );
  }, [candidatePerferencesResponse]);

  const physicalDisability = useMemo(() => {
    if (!candidatePerferencesResponse) return undefined;

    return Object.entries(
      candidatePerferencesResponse.physical_disabilities
    ).map(([key, value]) => ({
      id: key,
      value: value,
      label: value,
    }));
  }, [candidatePerferencesResponse]);

  const congnitiveDisability = useMemo(() => {
    if (!candidatePerferencesResponse) return undefined;

    return Object.entries(
      candidatePerferencesResponse.congnitive_disabilities
    ).map(([key, value]) => ({
      id: key,
      value: value,
      label: value,
    }));
  }, [candidatePerferencesResponse]);

  const socioEconomicStatus = useMemo(() => {
    if (!candidatePerferencesResponse) return undefined;

    return Object.entries(
      candidatePerferencesResponse.socio_economic_statuses
    ).map(([key, value]) => ({
      id: key,
      value: value,
      label: value,
    }));
  }, [candidatePerferencesResponse]);

  const { data: raceEthnicityResponse } = useSWR<
    TagsResponse<TagType.Ethnicity>
  >(API_ROUTES.RACE_ETHNICITY, get, {
    revalidateOnFocus: false,
  });

  const raceEthnicity = useMemo(() => {
    const list = parseArrayResponse<TagAttributes<TagType.Ethnicity>>(
      raceEthnicityResponse
    );
    return list.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [raceEthnicityResponse]);

  const { data: languageResponse } = useSWR<TagsResponse<TagType.Language>>(
    API_ROUTES.LANGUAGE,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const defaultLanguage = useMemo(
    () => languageResponse?.meta?.default_language_id || '',
    [languageResponse]
  );

  const languageOptions = useMemo(() => {
    const list =
      parseArrayResponse<TagAttributes<TagType.Language>>(languageResponse);

    const optionList = list.map(({ id, name }) => {
      return {
        id,
        value: id,
        label: name,
      };
    });

    return optionList;
  }, [languageResponse]);

  const workTypeList = useMemo(
    () => [
      {
        id: 'Full time',
        value: 'Full time',
        label: 'Full time',
      },
      {
        id: 'Part time',
        value: 'Part time',
        label: 'Part time',
      },
    ],
    []
  );

  const workModeList = useMemo(
    () => [
      {
        id: 'remote',
        value: 'remote',
        label: 'Remote',
      },
      {
        id: 'hybrid',
        value: 'hybrid',
        label: 'Hybrid',
      },
      {
        id: 'office',
        value: 'office',
        label: 'Office',
      },
    ],
    []
  );

  const datePostedList = useMemo(
    () => [
      {
        id: 'datePosted1',
        value: format(
          addMonths(new Date(), -3),
          "yyyy-MM-dd'T'00:00:00.000xxx"
        ),
        label: 'Last 3 months',
      },
      {
        id: 'datePosted2',
        value: format(
          addMonths(new Date(), -2),
          "yyyy-MM-dd'T'00:00:00.000xxx"
        ),
        label: 'Last 2 months',
      },
      {
        id: 'datePosted3',
        value: format(
          addMonths(new Date(), -1),
          "yyyy-MM-dd'T'00:00:00.000xxx"
        ),
        label: 'Last month',
      },
      {
        id: 'datePosted4',
        value: format(addWeeks(new Date(), -2), "yyyy-MM-dd'T'00:00:00.000xxx"),
        label: 'Last two weeks',
      },
      {
        id: 'datePosted5',
        value: format(addWeeks(new Date(), -1), "yyyy-MM-dd'T'00:00:00.000xxx"),
        label: 'Last week',
      },
      {
        id: 'datePosted6',
        value: format(new Date(), "yyyy-MM-dd'T'00:00:00.000xxx"),
        label: 'Today',
      },
    ],
    []
  );

  const salaryOptions = useMemo(
    () => [
      {
        id: '1',
        value: '10000',
        label: '10,000+',
      },
      {
        id: '2',
        value: '20000',
        label: '20,000+',
      },
      {
        id: '3',
        value: '30000',
        label: '30,000+',
      },
      {
        id: '4',
        value: '40000',
        label: '40,000+',
      },
      {
        id: '5',
        value: '50000',
        label: '50,000+',
      },
      {
        id: '6',
        value: '60000',
        label: '60,000+',
      },
      {
        id: '7',
        value: '70000',
        label: '70,000+',
      },
      {
        id: '8',
        value: '80000',
        label: '80,000+',
      },
      {
        id: '9',
        value: '90000',
        label: '90,000+',
      },
      {
        id: '10',
        value: '100000',
        label: '100,000+',
      },
    ],
    []
  );

  const currentYear = new Date().getFullYear();
  const graduateDateOptions = useMemo(() => {
    const years = [];
    for (let i = currentYear + 10; i >= currentYear - 10; i--) {
      years.push({ id: String(i), value: String(i), label: String(i) });
    }
    return years;
  }, [currentYear]);

  const { data: verifiedSocietiesOptionsResponse } =
    useSWR<StudentSocietiesResponse>(
      isOrganizationUser
        ? `${API_ROUTES.STUDENT_SOCIETIES}?per_page=20000&verified=true&basic_details=true`
        : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const verifiedSocietiesOptions = useMemo(() => {
    if (!verifiedSocietiesOptionsResponse?.data) return;

    const list = parseArrayResponse<StudentSocietyAttributes>(
      verifiedSocietiesOptionsResponse
    );
    return list.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [verifiedSocietiesOptionsResponse]);

  const disabilityFilterOptions = [
    {
      id: 'physical_disability',
      value: 'Has physical disability',
      label: 'Has physical disability',
    },
    {
      id: 'cognitive_disability',
      value: 'Has cognitive disability',
      label: 'Has cognitive disability',
    },
  ];

  const socioEconomicStatusFilterOptions = [
    {
      id: '1',
      value: '1',
      label: 'Lower socio-economic background',
    },
  ];

  return {
    areaOfResponsibilities,
    opportunityTypes,
    internshipKinds,
    internshipKindOptions,
    eventKinds,
    roleLevels,
    educationRequirementsOptions,
    currencyOptions,
    eventCategoriesList,
    eventCategoriesOptions,
    industryOptions,
    industryTypeOptions,
    topicOptions,
    topicTypeOptions,
    eventTypeOptions,
    eventModeOptions,
    companySizeOptions,
    employeesCountRange,
    universities,
    subjects,
    degrees,
    studyYearOptions,
    countries,
    cityCountryList,
    genders,
    physicalDisability,
    congnitiveDisability,
    socioEconomicStatus,
    raceEthnicity,
    languageOptions,
    offeringOptions,
    workTypeList,
    workModeList,
    datePostedList,
    defaultLanguage,
    cities,
    salaryOptions,
    experienceLevels,
    graduateDateOptions,
    verifiedSocietiesOptions,
    disabilityFilterOptions,
    socioEconomicStatusFilterOptions,
  };
};

export type UseCommonListReturnType = ReturnType<typeof useCommonList>;
