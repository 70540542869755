export const PAGE_ROUTES = {
  SIGN_IN_RECRUITER: '/company/signin',
  RECRUITER_SIGN_UP: '/company/signup',
  SIGN_IN_SOCIETY: '/society/signin',
  MANAGER_SIGN_UP: '/society/signup',
  FORGOT_PASSWORD_RECRUITER: '/company/forgot-password',
  RESET_PASSWORD_RECRUITER: '/company/reset-password',
  FORGOT_PASSWORD_SOCIETY: '/society/forgot-password',
  RESET_PASSWORD_SOCIETY: '/society/reset-password',
  EMAIL_VERIFICATION: '/email-verification',
  HOME: '/',
  LOGOUT: '/logout',
  COMPANY_EDIT: '/company/edit',
  COMPANIES: '/companies',
  COMPANY_PUBLIC: '/companies/[slug]',
  MESSAGES: '/messages',
  CAMPAIGNS: '/campaigns',
  NEWSLETTERS: '/newsletters',
  NEWSLETTER_PREVIEW: '/newsletters/preview',
  NEWSLETTER_CREATE: '/newsletters/create',
  NEWSLETTER_EDIT: '/newsletters/[id]/edit',
  APPLICANTS: '/applicants',
  JOBS_CREATE: '/jobs/create',
  JOBS_EDIT: '/jobs/[id]/edit',
  JOB_PUBLIC: '/jobs/[id]',
  INTERNSHIPS_CREATE: '/internships/create',
  INTERNSHIPS_EDIT: '/internships/[id]/edit',
  INTERNSHIP_PUBLIC: '/internships/[id]',
  PUBLIC_SEARCH_PAGES: '/[searchPageSlug]',
  EVENTS_CREATE: '/events/create',
  EVENTS_EDIT: '/events/[id]/edit',
  EVENT_PUBLIC: '/events/[id]',
  STUDENT_SOCIETIES: '/student-societies',
  SOCIETY_SPONSORSHIPS: '/sponsorships',
  DISCUSSIONS: '/discussions',
  SOCIETIES: '/societies',
  SOCIETY_PUBLIC: '/societies/[slug]',
  SHORTLINK_SOCIETY_PUBLIC: '/s/[slug]',
  UNIVERSITIES: '/universities',
  UNIVERSITIES_SOCIETIES: '/universities/[universitySlug]/societies',
  DASHBOARD: '/dashboard',
  QR_CODE: '/qr-code',
  SOCIETY_PROFILE: '/society-profile',
  SOCIETY_PROFILE_EDIT: '/society-profile/edit',
  CANDIDATE_SIGN_IN: '/signin',
  // CANDIDATE_SIGN_IN: '/candidate/signin',
  CANDIDATE_DASHBOARD: '/candidate/dashboard',
  CANDIDATE_MATCHES: '/candidate/matches',
  CANDIDATE_MATCH: '/candidate/matches/[slug]',
  CANDIDATE_MATCHES_SUCCESS: '/candidate/matches/success',
  CANDIDATE_MATCHES_GENERATING: '/candidate/matches/generating',
  CANDIDATE_MATCHES_SEEN_ALL: '/candidate/matches/seen-all',
  MANAGER: '/candidate/manager',
  FUNNEL_MATCHES: '/candidate/funnel-matches',
  GET_PERSONALISED_MATCHES: '/candidate/get-personalised-matches',
  SEARCH_RESULTS: '/search/results',
  COMPANIES_SEARCH: '/search/results?type=companies',
  SOCIETIES_SEARCH: '/search/results?type=communities',
  EVENTS_SEARCH: '/search/results?type=events',
  JOBS_SEARCH: '/search/results?type=jobs',
  INTERNSHIPS_SEARCH: '/search/results?type=internships',
  OPPORTUNITIES_SEARCH: '/search/results',
  // RECENT_SEARCH: '/search/results/recent',
  MATCHING_EXPERIENCE_PAGE: '/candidate/matches/[slug]',
  CANDIDATE_PROFILE: '/candidate/profile',
  CANDIDATE_SETTINGS: '/candidate/settings',
  CANDIDATE_NOTIFICATIONS: '/candidate/notifications',
  CANDIDATE_ONBOARDING: '/candidate/onboarding',
  CANDIDATE_BUILD_COVER_LETTER: '/candidate/build-cover-letter',
  EVENT_MANAGEMENT_DASHBOARD: '/campaigns/[id]',
  ATTENDEES: '/attendees',
  WAITLIST: '/waitlist',
  CAMPAIGNS_CREATE: '/campaigns/create',
  CAMPAIGNS_EDIT: '/campaigns/[id]/edit',
  HIRING_EDIT: '/hiring/[id]/edit',
  HIRING_CREATE: '/hiring/create',
  AI_SIGNUP: '/signin/ai',
  AI_ONBOARDING: '/candidate/ai/onboarding',
  CANDIDATE_REVIEW_APPLICATIONS: '/candidate/applications',
  HIRING: '/hiring',
  COMMITTEE: '/committee',
  COMMUNITY: '/community',
  RECRUITER_DASHBOARD: '/recruiter/dashboard',
  RECRUITER_ONBOARDING: '/recruiter/onboarding',
  RECRUITER_PARTNERSHIPS: '/recruiter/partnerships',
  TALENT_SOURCING: '/talent-sourcing',
  CANDIDATE_MESSAGES: '/candidate/messages',
  SOCIETY_ONBOARDING: '/society-profile/onboarding',
  SIGN_UP_MANAGER_SUCCESS: '/society/signup/success',
  SIGN_UP_RECRUITER_SUCCESS: '/company/signup/success',
  SOCIETY_SPONSOR_PUBLIC_PAGE: '/student-societies/[slug]',
  SOCIETY_SPONSOR_TOOL_PAGE: '/student-societies/[slug]/detail',
  RECRUITER_DISCUSSIONS_PAGE: '/recruiter/partnerships/[id]/discussions',
};
